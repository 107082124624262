import React, { Component } from "react";
import ManualCheckForm from "./manualCheck";
import facebook from "../images/facebook_1.svg";
import twitter from "../images/twitter_1.svg";
import instagram from "../images/instagram_1.svg";
import financials from "../images/atlas_finance_desk.svg";
import Mfinancials from "../images/mobile_finance.jpg";
//import close from "../images/white_x.svg";
import closeW from "../images/close_w.png";
import cookie1 from "../images/cookies1.png";
import cookie2 from "../images/cookies2.png";
import streamWait from "../images/2022vid_place.jpg";
import womenChildren from "../images/people.png";
import safeHome from "../images/home.png";
import zarmada from "../images/zarmada.png";
import atlasrfi from "../images/Atlas_RFI.png";
import newLogo from "../images/NEW_LOGO.svg";
import guidestar from "../images/guidestar.jpeg";
import ecfa from "../images/ecfa.svg";
import bbb from "../images/bbb.svg";
import navigator from "../images/navigator.png";
import peopleIcon from "../images/people_icon.png";
import programIcon from "../images/program_icon.png";
import globeIcon from "../images/globe.png";
import demand from "../images/demand_reduction.png";
import education from "../images/education.svg";
import caffeLussoBlack from "../images/CaffeLussoLogoBlack_web.png";
import strangierLogo from "../images/Stangier_Logo_Full_Color.png";
import purposeLogo from "../images/PurposeBoutiqueLogo_2022.png";
import { Alert } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import Iframe from "react-iframe";
import Pusher from "pusher-js";
import $ from "jquery";
import ReactAudioPlayer from "react-audio-player";
import DonationBar from "./donationBar";
import FlocklerEmbed from '@flockler/react-flockler-embed';

//import AudioPlayer from "./AudioPlayer";

import "../App.css";
//import LoginModal from "./loginmodal";

class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childKindfulWindow: null,
      kindfulWebview: null,

      videoComponent: null,
      audioComponent: null,

      renderingConfetti: false,

      popup1: false,
      popup2: false,
      popup3: false,
      popup4: false,
      popup5: false,
      popup6: false,
      popup7: false,
      popup8: false,
      popup9: false,
      popup10: false,
      issuesPrompt: true,
      show: true,
    };

    this.executeiFrame = this.executeiFrame.bind(this);
    this.openPopup1 = this.openPopup1.bind(this);
    this.closePopup1 = this.closePopup1.bind(this);
    this.openPopup2 = this.openPopup2.bind(this);
    this.closePopup2 = this.closePopup2.bind(this);
    this.openPopup3 = this.openPopup3.bind(this);
    this.closePopup3 = this.closePopup3.bind(this);
    this.openPopup4 = this.openPopup4.bind(this);
    this.closePopup4 = this.closePopup4.bind(this);
    this.openPopup5 = this.openPopup5.bind(this);
    this.closePopup5 = this.closePopup5.bind(this);
    this.openPopup6 = this.openPopup6.bind(this);
    this.closePopup6 = this.closePopup6.bind(this);
    this.openPopup7 = this.openPopup7.bind(this);
    this.closePopup7 = this.closePopup7.bind(this);
    this.openPopup8 = this.openPopup8.bind(this);
    this.closePopup8 = this.closePopup8.bind(this);
    this.openPopup9 = this.openPopup9.bind(this);
    this.closePopup9 = this.closePopup9.bind(this);
    this.openPopup10 = this.openPopup10.bind(this);
    this.closePopup10 = this.closePopup10.bind(this);
    this.closeIssues = this.closeIssues.bind(this);
  }
  //Used when swapping between the image and live
  videoIframe = (
    
    <div id="livefeed" className="flex-container fl1 youtubebox">
       <iframe width="560" height="315" src="https://www.youtube.com/embed/NSJFLmH2xA0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      {/*<iframe src="https://subsplash.com/+8269/embed/mi/+fq9p2pn?video" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen ></iframe>*/}
    </div>
  );

  //UPDATE THE YOUTUBE SOURCE EMBED LINK HERE
  youtubeIframe = (
    
    <div id="youtube" className="flex-container fl1 youtubebox">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/NSJFLmH2xA0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  );

  streamTimeImage = (
    
    <div className="flex-container fl1 youtubebox">
      <img
        className="item-frame waitimage"
        id="streamWaitingImage"
        src={streamWait}
        alt="Freedom Dinner Stream Time"
      />
    </div>
  );

  audioElement = (
      <ReactAudioPlayer
        src="/gala.mp3"
        autoPlay={true}
        loop={true}
        style={{display : "none"}}
        volume={0.15}
      />
  );

  // " http://localhost:3005" for testing
  // https://freedomdinner.org:30005 for production

  backendBaseUrl = "https://freedomdinner.org:30005";

  componentDidMount() {
    window.scrollTo(0, 0);
    const canvas = document.createElement("canvas");
    canvas.id = "confettiCanvas";
    document.body.appendChild(canvas);

    $(".dom_ex-trigger").click(function () {
      var my_link = $(this).attr("data-trigger");
      $(".ow-dom_ex-pop").hide();
      $("#" + my_link).fadeIn();
    });
    $(".ow-dom_ex-pop_close").click(function () {
      $(this).parent("div").fadeOut();
    });

    //Add handler for closing the kindful iframe as well as opening it
    window.addEventListener(
      "message",
      function (event) {
        if (event.data === "kclosed") {
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            this.state.childKindfulWindow.close();
          } else {
            this.setState({
              kindfulWebview: null,
            });
          }
        } else if (
          typeof event.data == "string" &&
          event.data.substr(0, 10) == "kredirect|"
        ) {
          var redirectUrl = event.data.replace("kredirect|", "");
          window.open(redirectUrl);
        }
      }.bind(this)
    );


    //Check with the server to see if the video is live
    fetch(this.backendBaseUrl + "/GetSiteProps")
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.props.video == true) {
            if (result.props.youtube) {
              this.setState({
                videoComponent: this.youtubeIframe,
              });
            } else {
              this.setState({
                videoComponent: this.videoIframe,
              });
              const script = document.createElement("script");

              script.src = "https://control.resi.io/webplayer/loader.min.js";
              script.async = false;

              document.body.appendChild(script);
            }
          } else {
            this.setState({
              videoComponent: this.streamTimeImage,
            });
          }
          if (result.props.music == true) {
            this.setState({
              audioComponent: this.audioElement,
            });
          } else {
            this.setState({
              audioComponent: null,
            });
          }
        },
        (error) => {
          console.log("There was an error getting the stream status.");
        }
      );

    var pusher = new Pusher("fbcbd44dde880c9cc623", {
      cluster: "us3",
    });

    var channel = pusher.subscribe("stream");
    channel.bind(
      "newStatus",
      function (pushData) {
        this.handlePusher(pushData);
      }.bind(this)
    );

    channel = pusher.subscribe("confetti");
    channel.bind(
      "newStatus",
      function (pushData) {
        window["initConfetti"]();
        if (!this.state.renderingConfetti) {
          window["render"]();
          this.state.renderingConfetti = true;
        }
      }.bind(this)
    );

    channel = pusher.subscribe("music");
    channel.bind(
      "newStatus",
      function (pushData) {
        fetch(this.backendBaseUrl + "/GetSiteProps")
          .then((res) => res.json())
          .then(
            (result) => {
              if (result.props.music == true) {
                this.setState({
                  audioComponent: this.audioElement,
                });
              } else {
                this.setState({
                  audioComponent: null,
                });
              }
            },
            (error) => {
              console.log("There was an error getting the stream status.");
            }
          );
      }.bind(this)
    );

    /* $("#resi-video-player").ready(() => {
      const script = document.createElement("script");

      script.src = "https://control.resi.io/webplayer/loader.min.js";
      script.async = false;

      document.body.appendChild(script);
    });*/
      
  }

  //Opens the Kindful donation iFrame in a react iFrame styling replicates the original in app.css kindful-webview
  executeiFrame() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.state.childKindfulWindow = window.open(
        "https://rescuefreedom.kindful.com/embeds/67fb0f1a-3672-4083-ad55-13ec8b794e53?p=https%3A&amp;embedded=true "
      );
    } else {
      var additionalParams = "";

      if (window.location.protocol != "https:") {
        additionalParams = "&ref=" + window.location.href + "&embedded=true";
      } else {
        additionalParams = "&embedded=true";
      }

      var urlString =
        "https://rescuefreedom.kindful.com/embeds/67fb0f1a-3672-4083-ad55-13ec8b794e53?p=" +
        window.location.protocol +
        additionalParams;
      this.setState({
        kindfulWebview: (
          <Iframe
            url={urlString}
            id="kindful-iframe"
            className="kindful-webview"
            display="initial"
            title="Kindful"
          />
        ),
      });
    }
  }

  handlePusher(data) {
    //Check with the server to see if the video is lives
    fetch(this.backendBaseUrl + "/GetSiteProps")
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.props.video == true) {
              this.setState({
                videoComponent: this.videoIframe,
              });
              const script = document.createElement("script");

              script.src = "https://control.resi.io/webplayer/loader.min.js";
              script.async = false;

              document.body.appendChild(script);
            
          } else if (result.props.youtube) {
              this.setState({
                videoComponent: this.youtubeIframe,
              });
            }else {
            this.setState({
              videoComponent: this.streamTimeImage,
            });
          }

          if (result.props.music == true) {
            this.setState({
              audioComponent: this.audioElement,
            });
          } else {
            this.setState({
              audioComponent: null,
            });
          }
          
        },
        (error) => {
          console.log("There was an error getting the stream status.");
        }
      );
  }

  openPopup1(event) {
    this.setState({
      popup1: true,
    });
  }

  closePopup1(event) {
    this.setState({
      popup1: false,
    });
  }

  openPopup2(event) {
    this.setState({
      popup2: true,
    });
  }

  closePopup2(event) {
    this.setState({
      popup2: false,
    });
  }

  openPopup3(event) {
    this.setState({
      popup3: true,
    });
  }

  closePopup3(event) {
    this.setState({
      popup3: false,
    });
  }

  openPopup4(event) {
    this.setState({
      popup4: true,
    });
  }

  closePopup4(event) {
    this.setState({
      popup4: false,
    });
  }

  openPopup5(event) {
    this.setState({
      popup5: true,
    });
  }

  closePopup5(event) {
    this.setState({
      popup5: false,
    });
  }

  openPopup6(event) {
    this.setState({
      popup6: true,
    });
  }

  closePopup6(event) {
    this.setState({
      popup6: false,
    });
  }

  openPopup7(event) {
    this.setState({
      popup7: true,
    });
  }

  closePopup7(event) {
    this.setState({
      popup7: false,
    });
  }

  openPopup8(event) {
    this.setState({
      popup8: true,
    });
  }

  closePopup8(event) {
    this.setState({
      popup8: false,
    });
  }

  openPopup8(event) {
    this.setState({
      popup8: true,
    });
  }

  openPopup9(event) {
    this.setState({
      popup9: true,
    });
  }

  closePopup9(event) {
    this.setState({
      popup9: false,
    });
  }

  openPopup10(event) {
    this.setState({
      popup10: true,
    });
  }

  closePopup10(event) {
    this.setState({
      popup10: false,
    });
  }

  closeIssues(event) {
    this.setState({
      issuesPrompt: false,
    });
  }

  render() {
    return (
      <div className="App">
        <CSSTransition
          in={this.state.issuesPrompt}
          timeout={500}
          classNames="slide"
          unmountOnExit
        >
          <div className="cookies-issue">
            <div className="closeX" onClick={this.closeIssues}>
              {/*<img src={close} alt="Close X" />*/}
            </div>
            <p style={{ cursor: "pointer" }} onClick={this.openPopup8}>
              Not seeing the chat window or having an issue donating?{" "}
              <span>Click Here</span>{" "}
            </p>
            <CSSTransition
              in={this.state.popup8}
              timeout={500}
              classNames="slide"
              unmountOnExit
            >
              <div id="cookiesPop" className="popup-prompt">
                <Alert>
                  <div className="closeX" onClick={this.closePopup8}>
                    {/*<img src={close} alt="Close X" />*/}
                  </div>
                  <div className="cookieInstuction">
                    <p>
                      Make sure you enable or allow cookies by checking for the
                      cookie icon the browser URL bar above
                      <br />
                      <br />
                      <img src={cookie1} alt="Chrome Cookie Alert" />
                      <br />
                      <img src={cookie2} alt="Edge Cookie Alert" />
                    </p>
                  </div>
                </Alert>
              </div>
            </CSSTransition>
          </div>
        </CSSTransition>
        {this.state.kindfulWebview}
        
        <div className="section1">
          <div className="site-header">
            <div className="site-logo">
              <img src={newLogo} alt="Atlas Free" /><br/>
              <span className="logoSub">Atlas Free presents</span>
            </div>
            <div className="site-title">
              <h1>2022 Virtual Freedom Gala</h1>
            </div>
            <div className="site-subtitle"><p>Our Freedom Gala is having an encore! Our 11th Annual Freedom Gala was a big success—we raised over $550,000! We won't quit until all are free. Will you help us reach our After Party goal of $100,000?
              <br/>
              <strong>BONUS:</strong> Every dollar will be matched up to $100,000! Watch the replay and contribute to our After Party goal. </p>
            </div>
            <div className="top-donate">
              <span className="donate-btn donate-trigger classy-trigger">donate</span>
            </div>
          </div>          

          <div className="width-80">
            <br/>

            <DonationBar></DonationBar>
            <div className="vidContainer">
                <div className="grid-box">
                  {this.state.videoComponent}

                  <div className="flex-container fl2">
                  <iframe
                    class="item-frame"
                    src="https://go.arena.im/embed/chat/atlas-free-2022-freedom-gala/atlas-free-2022-freedom-gala-global"
                    title="Chat Module"
                  ></iframe>
                  </div>
                </div>
               
                  <p>A global gathering to fight sex trafficking and exploitation.</p>
                  <div className="donate-btn donate-trigger classy-trigger">donate</div><br/>
              </div>
          </div>
        </div>
        <div className='backgroundRotation'>
            <div id='backgroundImage_content'>
                <div className="width-45">
                    <h3>We're gathering people from all different time zones for one mission: to put sexual exploitation out of business.</h3>
                    <p> Human trafficking is the fastest-growing crime globally, generating more annual profit than McDonald's, PepsiCo, and Disney combined. Unless people like you choose to do something, the cycle will continue. Help forge a future free from trafficking; invest in freedom and take your place in this global movement now. </p>
                    <div className="flex-container flex-column flex-center">
                    <div className="donate-btn classy-trigger">donate</div><br/>
                        <div style={{ cursor: "pointer" }}
                            onClick={this.openPopup2}
                            id="openPopup2"
                            className="button-alt">give via check</div><br/>
                        <div style={{ cursor: "pointer" }}
                            onClick={this.openPopup3}
                            className="button-alt">give via daf</div>
                        </div>
                    </div>
                </div>
            <div className='pic' id='pic16' />
            <div className='pic' id='pic15' />
            <div className='pic' id='pic14' />
            <div className='pic' id='pic13' />
            <div className='pic' id='pic12' />
            <div className='pic' id='pic11' />
            <div className='pic' id='pic10' />    
            <div className='pic' id='pic9' />
            <div className='pic' id='pic8' />
            <div className='pic' id='pic7' />
            <div className='pic' id='pic6' />
            <div className='pic' id='pic5' />
            <div className='pic' id='pic4' />
            <div className='pic' id='pic3' />
            <div className='pic' id='pic2' />
            <div className='pic' id='pic1' />
        </div>
        <CSSTransition
            in={this.state.popup2}
            timeout={300}
            classNames="fadeable"
            unmountOnExit
          >
            <div id="checkPop" className="popup-prompt">
              <Alert>
                <div className="closeX" onClick={this.closePopup2}>
                  <img src={closeW} alt="Close X" />
                </div>
                <p>
                  <strong>Send your check to:</strong>
                </p>
                <p>
                  Atlas Free
                  <br />
                  PO Box 77
                  <br />
                  Kirkland, WA 98083
                </p>
                <p>Write ‘Freedom Gala’ in the memo line.</p>
                <p>
                  <em>
                    Let us know the details of your gift so we can count it
                    toward our goal:
                  </em>
                </p>
                <ManualCheckForm
                  closePopup2={this.closePopup2}
                  closePopup3={this.closePopup3}
                  openPopupThanks={this.openPopup9}
                  isCheck={true}
                ></ManualCheckForm>
              </Alert>
            </div>
          </CSSTransition>
          <CSSTransition
            in={this.state.popup3}
            timeout={300}
            classNames="fadeable"
            unmountOnExit
          >
            <div id="dafPop" className="popup-prompt">
              <Alert>
                <div className="closeX" onClick={this.closePopup3}>
                  <img src={closeW} alt="Close X" />
                </div>
                <p>
                  <strong>Atlas Free</strong>
                  <br />
                  EIN/Tax ID: 16-1773392
                </p>
                <p>
                  Mailing Address: PO Box 77
                  <br />
                  Kirkland, WA 98083
                </p>
                <p>
                  Questions? Reach out to <br />
                  <a href="mailto:engage@atlasfree.org">
                    engage@atlasfree.org
                  </a>
                </p>
                <p>
                  <em>
                    Let us know the details of your gift so we can count it
                    toward our goal:
                  </em>
                </p>
                <ManualCheckForm
                  closePopup2={this.closePopup2}
                  closePopup3={this.closePopup3}
                  openPopupThanks={this.openPopup10}
                  isCheck={false}
                ></ManualCheckForm>
              </Alert>
            </div>
          </CSSTransition>
          <CSSTransition
            in={this.state.popup9}
            timeout={300}
            classNames="fadeable"
            unmountOnExit
          >
            <div id="checkPop" className="popup-prompt">
              <Alert>
                <div className="closeX" onClick={this.closePopup9}>
                  <img src={closeW} alt="Close X" />
                </div>
                <p>
                  Thank you for giving via a check. Your pledged gift will be counted toward the total tonight. Please send your check to PO Box 77 Kirkland, WA 98083. Enjoy the evening!
                  <br />
                  The Atlas Free Team
                </p>
              </Alert>
            </div>
          </CSSTransition>
          <CSSTransition
            in={this.state.popup10}
            timeout={300}
            classNames="fadeable"
            unmountOnExit
          >
            <div id="dafPop" className="popup-prompt">
              <Alert>
                <div className="closeX" onClick={this.closePopup10}>
                  <img src={closeW} alt="Close X" />
                </div>
                <p>
                  Thank you for giving via your donor-advised fund. Your pledged gift will be counted toward the total tonight. Our team will be in touch with you after the Gala. Enjoy the evening!
                  <br />
                  The Atlas Free Team
                </p>
              </Alert>
            </div>
          </CSSTransition>
          <div className="techQuestions">
          <p>
          Have a question or need help streaming? <br className="mobile-only"/><span><a href="sms:18883881811">Text us at (888) 388-1811</a></span>

          </p>

        </div>
        <div className="watchLarge">
          <p>
            Want to watch on a bigger screen?{" "}<br className="mobile-only"/>
            <span style={{ cursor: "pointer" }} onClick={this.openPopup7}>
              {" "}
              Click here.
            </span>
          </p>

          <CSSTransition
            in={this.state.popup7}
            timeout={300}
            classNames="fadeable"
            unmountOnExit
          >
            <div id="screenPop" className="popup-prompt">
              <Alert>
                <div className="closeX" onClick={this.closePopup7}>
                  <img src={closeW} alt="Close X" />
                </div>
                <p>
                  If you have an HDMI cable you can connect your computer
                  directly to your TV. You can participate in the live chat and
                  give by logging in on your smartphone or iPad while still
                  streaming on your TV.{" "}
                </p>
              </Alert>
            </div>
          </CSSTransition>
        </div>
  
        <div className="sponsor-row">
          <h3>Presented by</h3>
          <div>
            <img id="caffelusso" src={caffeLussoBlack} alt="Caffe Lusso Coffee Roasters" />
            <img id="zarmada" src={zarmada} alt="Zarmada" />
          </div>
          <h3 className="freedom-sponsor_title">Sponsored by</h3>
          <div>
            <img className="purposeLogo" id="purpose" src={purposeLogo} alt="Purpose Boutique" />
          </div>
          <div>
            <img className="strangierLogo" id="strangier" src={strangierLogo} alt="Purpose Boutique" />
          </div>
        </div>
        <div className="section3">
          <div className="width-40">
            <a href="https://www.atlasfree.org" target="_blank"><img src={atlasrfi} id="atlasrfi_logo" alt="Atlas Free - Formerly Rescue:Freesom" /></a>
            <h3>Sustainable work is <br className="mobile-only"/>locally led.</h3>
            <p className="small">We unite funders, adventurers, and every day people with over 40 local organizations across 25 countries. Together, we find and accelerate the most effective frontline initiatives and create resources and tools to scale the fight against human trafficking.
            ‍<br/><span>It's working.</span></p>
          </div>
          <div className="width-60">
            <div className="icon-grid-container">
              <img className="gridItem" src={globeIcon} alt="Globe"/>
              <img className="gridItem" src={peopleIcon} alt="People"/>
              <img className="gridItem" src={programIcon} alt="Programs"/>
              <p className="small gridItem"><span>We work with over 40 local partners spanning 25 countries to fight sex trafficking daily.</span></p>
              <p className="small gridItem"><span>635,169 people were impacted through aftercare, prevention, outreach, awareness, and intervention programs since 2012.  </span></p>
              <p className="small gridItem"><span>Over 250 unique programs holistically combating trafficking and scaling the global movement.</span></p>
            </div>
            <div className="icon-grid-container">
              <img className="gridItem" src={safeHome} alt="Safe Home"/>
              <img className="gridItem" src={womenChildren} alt="Women and Children"/>
              <img className="gridItem" src={demand} alt="Demand Reduction"/>
              <p className="small gridItem"><span>66 safe homes operating worldwide provide holistic care to help victims become survivors.</span></p>
              <p className="small gridItem"><span>So far this year, 615 women and children have been set free from sex trafficking.</span></p>
              <p className="small gridItem"><span>So far this year, 8,077 attempts to purchase sex have been disrupted.</span></p>
            </div>
          </div>
          <span className="btn-blue donate-btn classy-trigger">donate now</span>

        </div>
        <div className="financials">
          <div className="width-60">
            <img
              alt="2021 Financials"
              className="desktop-only"
              src={financials}
            />
            <img
              alt="2021 Financials"
              className="mobile-only"
              src={Mfinancials}
            />
          </div>
          <div className="endorse">
          <ul>
          <li>
              <a
                href="https://www.charitynavigator.org/ein/161773392"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={navigator} alt="Charity Navigator" id="navigator" />
              </a>
            </li>
            <li>
              <a
                href="http://www.guidestar.org/profile/16-1773392"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={guidestar} alt="Guidestar" id="guidestar" />
              </a>
            </li>
            <li>
              <a
                href="http://www.give.org/charity-reviews/national/human-services/rescue-freedom-international-in-kirkland-wa-43889"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={bbb} alt="BBB" id="bbb" />
              </a>
            </li>
            <li>
              <a
                href="http://www.ecfa.org/MemberProfile.aspx?ID=41354"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={ecfa} alt="ECFA" id="ecfa" />
              </a>
            </li>
          </ul>
          <p>EIN 16-1773392</p>
        </div>
        </div>
        <div className="social-wall">
          <h3 className="social-title">Share your #freedomgala2022 on social media</h3>
          {/*<div id="flockler-embed-1836b7e058e0884ab3f3b64926be2031"></div>*/}
          <FlocklerEmbed siteUuid="1835ba13f44075070915ee193590a1d1" embedUuid="1836b7e058e0884ab3f3b64926be2031" />
        </div>
        <div className="footer">
          <p>
            Have any questions? <br className="mobile-only"/>Email us:
            <br />
            <a class="foot-link" href="mailto:engage@atlasfree.org">
              engage@atlasfree.org
            </a>
          </p>
          <h3>Thank you for your support.</h3>
          <ul>
            <li>
              <a
                href="https://www.facebook.com/atlasfreeorg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="facebook" src={facebook} />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/atlasfreeorg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="twitter" src={twitter} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/atlasfree"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="instgram" src={instagram} />
              </a>
            </li>
          </ul>
          <span>@atlasfree</span>
        </div>
        {this.state.audioComponent}
      </div>
    );
  }
}

export default HomeScreen;
