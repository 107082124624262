import React, { Component } from "react";
import HomeScreen from "./home";
import "../App.css";
import "../styles.css";
import logo from "../images/Atlas_login_logo.svg";
import LoginScreen from "./login";
import { CSSTransition } from "react-transition-group";
import { Alert } from "react-bootstrap";

/* Description of component
  A simple register screen
*/
class RegisterScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      howMany: 1,
      transition: true,
      error: false,
      thankYou: false,
    };

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeFName = this.handleChangeFName.bind(this);
    this.handleChangeLName = this.handleChangeLName.bind(this);
    this.handleChangeHowMany = this.handleChangeHowMany.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.backToLogin = this.backToLogin.bind(this);
  }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleChangeFName(event) {
    this.setState({ firstName: event.target.value });
  }

  handleChangeLName(event) {
    this.setState({ lastName: event.target.value });
  }

  handleChangeHowMany(event) {
    this.setState({ howMany: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    // CHANGE THIS ADDRESS TO FINAL ADDRESS IN PRODUCTION
    // http://localhost:3005 for testing
    // http://freedomdinner.org:30005 for production

    var backendBaseUrl = "https://freedomdinner.org:30005";

    //If any value is empty then reject the user
    if (
      this.isStringEmpty(this.state.email) ||
      this.isStringEmpty(this.state.firstName) ||
      this.isStringEmpty(this.state.lastName)
    ) {
      this.setState({
        error: true,
      });
      return;
    }

    var payload = {
      email: this.state.email.replace(",", ""),
      firstName: this.state.firstName.replace(",", ""),
      lastName: this.state.lastName.replace(",", ""),
      howMany: this.state.howMany,
    };
    var xhttp = new XMLHttpRequest();
    xhttp.open("POST", backendBaseUrl + "/userRegister");
    xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhttp.onload = function () {
      if (xhttp.status === 200) {
        var res = JSON.parse(xhttp.response);
        //Registration succeeded
        //Transition to home and pass in returned account data
        /*var homeScreen = [];
        homeScreen.push(<HomeScreen appContext={this.props.appContext} />);
        this.props.appContext.setState({
          homeScreen: homeScreen,
          email: res.email,
          firstName: res.firstName,
          lastName: res.lastName,
        });*/
        this.setState({
          transition: false,
          thankYou: true,
        });
      } else {
        // registeration failed
        // ERROR
        alert(xhttp.status);
        this.setState({
          error: true,
        });
      }
    }.bind(this);
    xhttp.send(JSON.stringify(payload));
  }

  isStringEmpty(string) {
    return string.length === 0 || !string.trim();
  }

  backToLogin(event) {
    event.preventDefault();

    var loginScreen = [];
    loginScreen.push(<LoginScreen appContext={this.props.appContext} />);
    this.props.appContext.setState({
      loginScreen: loginScreen,
    });
    this.setState({
      transition: false,
    });
  }

  render() {
    return (
      <div>
        <CSSTransition
          in={this.state.transition}
          timeout={300}
          classNames="fadeable"
          unmountOnExit
        >
          <div className="registerPage">
            <div className="registerPage_container">
              <img src={logo} alt="Event Logo" id="atlas-logo" />
              <h5>virtual freedom gala</h5>

              <form onSubmit={this.handleSubmit}>
                <label>
                  <input
                    type="text"
                    value={this.state.email}
                    onChange={this.handleChangeEmail}
                    id="email"
                    name="email"
                    placeholder="Email Address"
                  />
                </label>
                <br />
                <label>
                  <input
                    type="text"
                    value={this.state.firstName}
                    onChange={this.handleChangeFName}
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                  />
                </label>
                <br />
                <label>
                  <input
                    type="text"
                    value={this.state.lastName}
                    onChange={this.handleChangeLName}
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                  />
                </label>
                <br />
                <h6>How many people are joining you?</h6>
                <label>
                  <input
                    type="number"
                    min="1"
                    value={this.state.howMany}
                    onChange={this.handleChangeHowMany}
                    id="howMany"
                    name="howMany"
                    placeholder="How many people are joining you?"
                  />
                </label>
                <br />
                <input type="submit" value="Enter Event" />
              </form>
              <CSSTransition
                in={this.state.error}
                timeout={300}
                classNames="fadeable"
                unmountOnExit
              >
                <div className="register-error">
                  <Alert
                    variant="primary"
                    dismissible
                    onClose={() => this.setState({ error: false })}
                  >
                    <Alert.Heading>Registration Error</Alert.Heading>
                    <p>Looks like there was an error with what you entered</p>
                  </Alert>
                </div>
              </CSSTransition>
            </div>
          </div>
        </CSSTransition>
        <CSSTransition
          in={this.state.thankYou}
          timeout={300}
          classNames="fadeable"
          unmountOnExit
        >
          <div className="register-thank">
            <div className="registerPage">
              <div className="registerPage_container">
                <p>Thanks for registering</p>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  }
}

export default RegisterScreen;
