import React, { Component } from "react";
import HomeScreen from "./home";
import "../App.css";
import "../styles.css";
import RegisterScreen from "./register";
import { Button, Alert } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import logo from "../images/logo_white.svg";
import background_houses from "../images/houses.png";
import sponsor from "../images/lusso.svg";
import splashLogo from "../images/fd-home.svg";
//import close from "../images/close.png";
//import liveNow from "../images/FREEDOM-DINNER-VIRTUAL-PAGE-star.png";
import Pusher from "pusher-js";
//import LoginModal from "./loginmodal";
import $ from "jquery";
import close from "../images/close_w.png";

/* Description of component
  A simple login screen
*/
class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      howMany: 1,
      noAuth: false,
      transition: true,
      splashScreen: null,

      liveImage: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.openRegistration = this.openRegistration.bind(this);

    this.closeAndTransition = this.closeAndTransition.bind(this);

  }

  //Used when swapping between the splash and live login
  splashScreenPage = (
    <div className="splashscreen">
      <div className="splashbox">
        <img src={splashLogo} alt="Freedom Gala" id="splashlogo" />
        <h5>September 25th at 5:30 P.M. PDT</h5>
        <a
          className="donate-btn"
          href="https://www.atlasfree.org/registerfreedomgala"
          target="_blank"
        >
          REGISTER NOW
        </a>
      </div>
    </div>
  );

  // CHANGE THIS ADDRESS TO FINAL ADDRESS IN PRODUCTION
  // http://localhost:3005 for testing

  // https://freedomdinner.org:30005 for production
  backendBaseUrl = "https://freedomdinner.org:30005";

  handleChange(event) {
    this.setState({ email: event.target.value });
  }

  handleChange2(event) {
    this.setState({ howMany: event.target.value });
  }

  isStringEmpty(string) {
    return string.length === 0 || !string.trim();
  }

  handleSubmit(event) {
    event.preventDefault();

    //If any value is empty then reject the user
    if (this.isStringEmpty(this.state.email)) {
      this.setState({
        noAuth: true,
      });
      return;
    }

    var payload = {
      email: this.state.email.replace(",", ""),
      howMany: this.state.howMany,
    };
    var xhttp = new XMLHttpRequest();
    xhttp.open("POST", this.backendBaseUrl + "/userLogin");
    xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhttp.onload = function () {
      if (xhttp.status == 200) {
        //Transition to home and pass in returned account data
        var res = JSON.parse(xhttp.response);
        console.log(res);

        var homeScreen = [];
        homeScreen.push(<HomeScreen appContext={this.props.appContext} />);
        this.props.appContext.setState({
          homeScreen: homeScreen,
          email: res.email,
          firstName: res.firstName,
          lastName: res.lastName,
        });
        this.setState({
          transition: false,
        });
        localStorage.setItem("loggedIn", true);
        localStorage.setItem("email", JSON.parse(res.email));
        localStorage.setItem("firstName", res.firstName);
        localStorage.setItem("lastName", res.lastName);
        localStorage.setItem("howMany", res.howMany);
      } else {
        // login failed and we need to register
        this.setState({
          noAuth: true,
        });
      }
    }.bind(this);
    xhttp.send(JSON.stringify(payload));
  }

  openRegistration(event) {
    var registerScreen = [];
    registerScreen.push(<RegisterScreen appContext={this.props.appContext} />);
    this.props.appContext.setState({
      registerScreen: registerScreen,
    });
    this.setState({
      transition: false,
    });
  }
  /*
  //Used when swapping between the image and live video
  liveImageStar = (
    <img
      id="streamLiveImage"
      src={liveNow}
      alt="Freedom Dinner Stream Live Now"
    />
  );

  notLiveImage = (<div></div>);

  componentDidMount() {
    //Check with the server to see if the video is live
    fetch(this.backendBaseUrl + "/GetSiteProps")
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.props.video == true) {
            this.setState({
              liveImage: this.liveImageStar,
            });
          } else {
            this.setState({
              liveImage: this.notLiveImage,
            });
          }
        },
        (error) => {
          console.log("There was an error getting the stream status.");
        }
      );

    var pusher = new Pusher("fbcbd44dde880c9cc623", {
      cluster: "us3",
    });

    var channel = pusher.subscribe("stream");
    channel.bind(
      "newStatus",
      function (pushData) {
        this.handlePusher(pushData);
      }.bind(this)
    );
  }

  handlePusher(data) {
    //Check with the server to see if the video is live
    fetch(this.backendBaseUrl + "/GetSiteProps")
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.props.video == true) {
            this.setState({
              liveImage: this.liveImageStar,
            });
          } else {
            this.setState({
              liveImage: this.notLiveImage,
            });
          }
        },
        (error) => {
          console.log("There was an error getting the stream status.");
        }
      );
  }
*/
  componentDidMount() {
    $("#closeModal").click(function () {
      $("#loginPage_modal").fadeOut();
    });

    const loggedInUser = localStorage.getItem("loggedIn");
    const email = localStorage.getItem("email");
    const howMany = localStorage.getItem("howMany");
    console.log(email);

    if (loggedInUser && email && howMany) {
      var payload = {
        email: email,
        howMany: howMany,
      };
      var xhttp = new XMLHttpRequest();
      xhttp.open("POST", this.backendBaseUrl + "/userLogin");
      xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xhttp.onload = function () {
        if (xhttp.status == 200) {
          //Transition to home and pass in returned account data
          var res = JSON.parse(xhttp.response);
          var homeScreen = [];
          homeScreen.push(<HomeScreen appContext={this.props.appContext} />);
          this.props.appContext.setState({
            homeScreen: homeScreen,
            email: res.email,
            firstName: res.firstName,
            lastName: res.lastName,
          });
          this.setState({
            transition: false,
          });
          localStorage.setItem("loggedIn", true);
          localStorage.setItem("email", res.email);
          localStorage.setItem("firstName", res.firstName);
          localStorage.setItem("lastName", res.lastName);
          localStorage.setItem("howMany", res.howMany);
        } else {
          // login failed and we need to register
          this.setState({
            noAuth: true,
          });
        }
      }.bind(this);
      xhttp.send(JSON.stringify(payload));
    }

    var pusher = new Pusher("fbcbd44dde880c9cc623", {
      cluster: "us3",
    });

    var channel = pusher.subscribe("splash");
    channel.bind(
      "newStatus",
      function (pushData) {
        //Check with the server to see if the splash is live
        fetch(this.backendBaseUrl + "/GetSiteProps")
          .then((res) => res.json())
          .then(
            (result) => {
              if (result.props.splashScreen == true) {
                this.setState({
                  splashScreen: this.splashScreenPage,
                });
              } else {
                this.setState({
                  splashScreen: null,
                });
              }
            },
            (error) => {
              console.log(
                "There was an error getting the site splashscreen status."
              );
            }
          );
      }.bind(this)
    );

    //Init to splashscreen
    this.setState({
      splashScreen: this.splashScreenPage,
    });

    //Check with the server to see if the splash is live
    fetch(this.backendBaseUrl + "/GetSiteProps")
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.props.splashScreen == true) {
            this.setState({
              splashScreen: this.splashScreenPage,
            });
          } else {
            this.setState({
              splashScreen: null,
            });
          }
        },
        (error) => {
          console.log(
            "There was an error getting the site splashscreen status."
          );
        }
      );
  }

  closeAndTransition() {
    var homeScreen = [];
    homeScreen.push(<HomeScreen appContext={this.props.appContext} />);
    this.props.appContext.setState({
      homeScreen: homeScreen,
      email: "",
      firstName: "",
      lastName: "",
    });
    this.setState({
      transition: false,
    });
  }
  render() {
    //Button that appears and allows us to register if no email found
    let authPrompt;

    if (this.state.noAuth) {
      authPrompt = (
        <div className="register-prompt">
          <Alert variant="primary" dismissible>
            <p>
              <strong>Haven't Registered?</strong>
              <br />
              Register for the Freedom Dinner now!
            </p>
            <Button onClick={this.openRegistration}>Register</Button>
          </Alert>
        </div>
      );
    } else {
      authPrompt = <div></div>;
    }

    if (this.state.splashScreen != null) {
      return this.state.splashScreen;
    }

    return (
      <CSSTransition
        in={this.state.transition}
        timeout={300}
        classNames="fadeable"
        unmountOnExit
      >
        <div className="loginPage">
          <div id="loginPage_modal" className="login_modal">
            <div className="login-modal_box">
              <div className="closeX">
                <img id="closeModal" onClick={this.closeAndTransition} src={close} alt="Close Modal" />
              </div>
              <h3>
                Thank you for joining us for the 10th Annual Freedom Dinner!{" "}
              </h3>
              <p>
                The live event is over, but you can still watch the full program
                and donate towards our after party goal!
              </p>
              <div id="loginPage_watchBtn" onClick={this.closeAndTransition} className="watch-btn">
                WATCH NOW
              </div>
            </div>
          </div>
          <div className="loginPage_container">
            <img src={logo} alt="Event Logo" id="rfi-logo" />
            <div id="gate-live">
              <div id="gate-live_top">
                <h5>virtual freedom dinner</h5>
                <h3>
                  <span>A Global Gathering to Fight Sex Trafficking</span>
                </h3>
                <h3>
                  Join us online here,<span> tonight </span>at 5:30 P.M. PDT
                </h3>
              </div>
              <div className="loginPage_contentbox">
                <h3 id="login-toph3">
                  If you’ve registered,
                  <br />
                  enter your email to join in.
                </h3>
                <form onSubmit={this.handleSubmit}>
                  <label>
                    <input
                      type="text"
                      value={this.state.email}
                      onChange={this.handleChange}
                      id="email"
                      name="email"
                      placeholder="Email"
                    />
                  </label>
                  <h3 id="login-num">
                    How many people
                    <br />
                    are in your party?
                  </h3>
                  <label>
                    <input
                      type="number"
                      min="1"
                      value={this.state.howMany}
                      onChange={this.handleChange2}
                      id="howMany"
                      name="howMany"
                      placeholder="How many people are joining you?"
                    />
                  </label>
                  <br />
                  <input
                    className="submitBtn"
                    type="submit"
                    value="Enter the Event"
                  />
                </form>
                <br />
                <CSSTransition
                  in={this.state.noAuth}
                  timeout={300}
                  classNames="fadeable"
                  unmountOnExit
                >
                  <div className="register-prompt">
                    <Alert>
                      <Alert.Heading>Oops!</Alert.Heading>
                      <p>
                        We are having trouble finding
                        <br /> your email address.
                        <br />
                        Please try again or register below.
                      </p>
                    </Alert>
                  </div>
                </CSSTransition>
                <div id="inpage-reg" className="register-prompt">
                  <Alert>
                    <p>
                      Haven't Registered? Tickets are free!
                      <br />
                      Register now and enter the event.
                    </p>
                    <Button onClick={this.openRegistration}>
                      Register Now
                    </Button>
                  </Alert>
                </div>
              </div>
              <div id="login-sponsor">
                <p>Presented by</p>
                <img src={sponsor} alt="Event Sponsor" />
              </div>
            </div>
          </div>

          <img
            src={background_houses}
            id="background_houses"
            alt="Login Page Design"
          />
        </div>
      </CSSTransition>
    );
  }
}

export default LoginScreen;
// {this.state.liveImage}
