import React, { Component } from "react";
import Pusher from "pusher-js";
import "../styles.css";
import { red400 } from "material-ui/styles/colors";
import { CSSTransition } from "react-transition-group";

/* Description of component
  Currently donated amount and the goal amount
  A Thank you to the recent donor in an "alert"
  A live donation bar based on the amount raised
*/
class DonationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      data: null,

      //transactionData is queue of latest donations that are not yet shown
      //alertShown is bool that determines if donation is on screen
      //currentAlert is the currently shown donation
      transactionData: [],
      alertShown: false,
      currentAlert: null,

      // Change this to alter the time alerts are shown
      // Be sure to modify the css animation as well (it is set to be 10 seconds by default)
      alertShownTime: 9000,
    };
  }

  //This web address can be used for live campaign stats (STORED AS JSON), https://rescuefreedom.kindful.com/public/api/v1/campaigns/efd3a58e-3cdb-4d3c-9b51-fd88f782923f.json
  //We are using a local JSON file so that manual changes can be made by RFI using a private form
  //A Webhook updates the local JSON file as well located in server/server.js
  componentDidMount() {
    var pusher = new Pusher("fbcbd44dde880c9cc623", {
      cluster: "us3",
    });

    //Subscribe to pusher channel to GET our JSON data
    //This also sends the new transaction made that caused the update
    // "http://localhost:3001" for testing
    // /api/ for production
    var backendBaseUrl = "https://freedomdinner.org:30005";

    var channel = pusher.subscribe("transaction");
    channel.bind(
      "newDonation",
      function (pushData) {
        fetch(backendBaseUrl + "/currentCampaign")
          .then((res) => res.json())
          .then(
            (result) => {
              var newTransactionData;
              newTransactionData = this.state.transactionData.concat(pushData.transactionData);
              //Only add the transaction to show if there is a name
              /*if(pushData.transactionData.data.person)
                newTransactionData = this.state.transactionData.concat(pushData.transactionData);
              else
                newTransactionData= this.state.transactionData;*/

              this.setState({
                isLoaded: true,
                data: result.campaign,
                transactionData: newTransactionData,
              });
            },

            (error) => {
              this.setState({
                isLoaded: true,
                error,
              });
            }
          );
      }.bind(this)
    );

    fetch(backendBaseUrl + "/currentCampaign")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result.campaign,
          });
        },

        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  // Simple function that just checks to see if we have transaction data
  getNewestDonor() {
    var ret;
    if (this.state.currentAlert != null) {
      ret =  (this.state.currentAlert.data.object.amount_in_cents / 100).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }) + " Donation" ;
    } else {
      ret = "";
    }

    return ret;
  }

  render() {
    /*Handle donor alerts */

    //If we're not currently showing an alert see if we've recieved any donor transaction data
    //Show the alert and set timeout to reset the current alert
    if (!this.state.alertShown && this.state.currentAlert == null) {
      if (this.state.transactionData.length > 0) {
        this.state.currentAlert = this.state.transactionData.shift();
        this.state.alertShown = true;

        setTimeout(function () {
          this.setState({
            alertShown: false
          })
        }.bind(this), this.state.alertShownTime);

        setTimeout(function () {
          this.setState({
            currentAlert: null
          })
        }.bind(this),  this.state.alertShownTime  + 2000);
      }
    }
    /* End of donor alert queue logic */

    const { error, isLoaded, data } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div id="donation-total-raised">

          <CSSTransition
            in={this.state.alertShown}
            timeout={1000}
            classNames="fadeable"
            unmountOnExit
          >
            <div class="donationAlert" id="donation-latest-donor">
              <span>{this.getNewestDonor()}</span>
            </div>
          </CSSTransition>
          <div id="donation-bar-background">
            <div
              id="donation-bar-graphic"
              style={{
                width:
                (Math.min((data.total_raised_amount_in_cents /
                  data.goal_amount_in_cents) *100 ,100)).toString() + "%",
                height: 40,
              }}
            ></div>
          </div>
          <p>
            {(data.total_raised_amount_in_cents / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}{" "}
            /{" "}
            {(data.goal_amount_in_cents / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </p>
        </div>
      );
    }
  }
}

export default DonationBar;
