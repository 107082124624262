import React, { Component } from "react";
import "./App.css";
import "./styles.css";

import LoginScreen from "./components/login";
import HomeScreen from "./components/home";
import RegisterScreen from "./components/register";
import PasswordScreen from "./components/password";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginScreen: [],
      registerScreen: [],
      homeScreen: [],
      passwordScreen: [],

      firstName: "",
      lastName: "",
      email: "",
    };
  }
  
  backendBaseUrl = "https://freedomdinner.org:30005";

  componentWillMount() {
    fetch(this.backendBaseUrl + "/GetSiteProps")
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.props.login == true) {
            var passwordScreen = [];
            passwordScreen.push(<PasswordScreen appContext={this} />);
            this.setState({
              passwordScreen: passwordScreen,
            }); 
          } else {
            var homeScreen = [];
            homeScreen.push(<HomeScreen appContext={this} />);
            this.setState({
              homeScreen: homeScreen,
            });
          }
        },
        (error) => {
          console.log("There was an error getting the site status.");
        }
      );//*/
   /* var loginScreen = [];
    loginScreen.push(<LoginScreen appContext={this} />);
    this.setState({
      loginScreen: loginScreen,
    }); */
        
    /*var homeScreen = [];
    homeScreen.push(<HomeScreen appContext={this} />);
    this.setState({
      homeScreen: homeScreen,
    });*/
    
    /*
    var registerScreen = [];
    registerScreen.push(<RegisterScreen appContext={this} />);
    this.setState({
      registerScreen: registerScreen,
    });
    */
    
   
  }

  render() {
    return (
      <div className="App">
        {this.state.passwordScreen}
        {this.state.loginScreen}
        {this.state.registerScreen}
        {this.state.homeScreen}
      </div>
    );
  }
}
export default App;
