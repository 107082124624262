import React, { Component } from "react";
import HomeScreen from "./home";
import "../App.css";
import "../styles.css";
import logo from "../images/login_logo.png";

import { CSSTransition } from "react-transition-group";
import { Alert } from "react-bootstrap";

/* Description of component
  A simple password screen to access the site
*/
class PasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      howMany: "1",
      firstName: "",
      lastName: "",
      transition: true,
      error: false,
    };

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeFirstName = this.handleChangeFirstName.bind(this);
    this.handleChangeLastName = this.handleChangeLastName.bind(this);
    this.handleChangeHowMany = this.handleChangeHowMany.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleChangeFirstName(event) {
    this.setState({ firstName: event.target.value });
  }

  handleChangeLastName(event) {
    this.setState({ lastName: event.target.value });
  }

  handleChangeHowMany(event) {
    this.setState({ howMany: event.target.value });
  }

  // " http://localhost:3005" for testing
  // https://freedomdinner.org:30005 for production

  backendBaseUrl = "https://freedomdinner.org:30005";

  handleSubmit(event) {
    event.preventDefault();
    // CHANGE THIS ADDRESS TO FINAL ADDRESS IN PRODUCTION
    // http://localhost:3005 for testing
    // http://freedomdinner.org:30005 for production

    //var backendBaseUrl = "https://freedomdinner.org:30005";

    //If any value is empty then reject the user
    /* if (
      this.isStringEmpty(this.state.password) 
    ) {
      this.setState({
        error: true,
      });
      return;
    }

    if(this.state.password == "!FREE2022!"){
      event.preventDefault();
  //Correct password takes us to the login
    var loginScreen = [];
    loginScreen.push(<LoginScreen appContext={this.props.appContext} />);
    this.props.appContext.setState({
      loginScreen: loginScreen,
    });
    this.setState({
      transition: false,
    });
    }else{
      this.setState({
          error: true,
        });
    }*/

    //If any value is empty then reject the user
    if (this.isStringEmpty(this.state.email)) {
      this.setState({
        noAuth: true,
      });
      return;
    }
    if (this.isStringEmpty(this.state.firstName)) {
      this.setState({
        noAuth: true,
      });
      return;
    }
    if (this.isStringEmpty(this.state.lastName)) {
      this.setState({
        noAuth: true,
      });
      return;
    }

    var payload = {
      email: this.state.email.replace(",", ""),
      firstName: this.state.firstName.replace(",", ""),
      lastName: this.state.lastName.replace(",", ""),
      howMany: this.state.howMany.replace(",", ""),
    };

    var xhttp = new XMLHttpRequest();
    xhttp.open("POST", this.backendBaseUrl + "/userLogin", false);
    xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhttp.onload = function () {
      if (xhttp.status == 200) {
        if (xhttp.response != null && !this.isStringEmpty(xhttp.response)) {
          //Transition to home and pass in returned account data
          var res = JSON.parse(xhttp.response);
          console.log(res);

          var homeScreen = [];
          homeScreen.push(<HomeScreen appContext={this.props.appContext} />);
          this.props.appContext.setState({
            homeScreen: homeScreen,
            email: res.email,
          });
          this.setState({
            transition: false,
          });
          localStorage.setItem("loggedIn", true);
          localStorage.setItem("email", res.email);
          localStorage.setItem("firstName", res.firstName);
          localStorage.setItem("lastName", res.lastName);
          localStorage.setItem("howMany", res.howMany);
        } else {
          var xhttp2 = new XMLHttpRequest();
          xhttp2.open("POST", this.backendBaseUrl + "/userRegister", false);
          xhttp2.setRequestHeader(
            "Content-Type",
            "application/json;charset=UTF-8"
          );
          xhttp2.onload = function () {
            if (xhttp2.status == 200) {
              //Transition to home and pass in returned account data
              var res = JSON.parse(xhttp2.response);
              console.log(res);

              var homeScreen = [];
              homeScreen.push(
                <HomeScreen appContext={this.props.appContext} />
              );
              this.props.appContext.setState({
                homeScreen: homeScreen,
                email: res.email,
              });
              this.setState({
                transition: false,
              });
              localStorage.setItem("loggedIn", true);
              localStorage.setItem("email", res.email);
              localStorage.setItem("firstName", res.firstName);
              localStorage.setItem("lastName", res.lastName);
              localStorage.setItem("howMany", res.howMany);
            }
          }.bind(this);
          xhttp2.send(JSON.stringify(payload));
        }
      }
    }.bind(this);
    xhttp.send(JSON.stringify(payload));
  }

  isStringEmpty(string) {
    return string.length === 0 || !string.trim();
  }

  render() {
    return (
      <div>
        <CSSTransition
          in={this.state.transition}
          timeout={300}
          classNames="fadeable"
          unmountOnExit
        >
          <div className="registerPage">
            <div className="registerPage_container">
              <img src={logo} alt="Event Logo" id="atlas-logo" />
              <p>Welcome to the 2022 Virtual</p>
              <h1>Freedom Gala</h1>

              <form onSubmit={this.handleSubmit}>
                <label>
                  <input
                    type="text"
                    value={this.state.firstName}
                    onChange={this.handleChangeFirstName}
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                  />
                </label>
                <label>
                  <input
                    type="text"
                    value={this.state.lastName}
                    onChange={this.handleChangeLastName}
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                  />
                </label>
                <label>
                  <input
                    type="text"
                    value={this.state.email}
                    onChange={this.handleChangeEmail}
                    id="email"
                    name="email"
                    placeholder="Email"
                  />
                </label>
                <br />
                <input type="submit" value="Enter" />
              </form>
              <br />
              <CSSTransition
                in={this.state.error}
                timeout={300}
                classNames="fadeable"
                unmountOnExit
              >
                <div className="register-error">
                  <Alert
                    variant="primary"
                    dismissible
                    onClose={() => this.setState({ error: false })}
                  >
                    <Alert.Heading>Email Error</Alert.Heading>
                    <p>Looks like the Email entered was incorrect</p>
                  </Alert>
                </div>
              </CSSTransition>
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  }
}

export default PasswordScreen;
