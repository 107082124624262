import React, { Component } from "react";
import "../App.css";
import "../styles.css";

import { CSSTransition } from "react-transition-group";
import { Alert } from "react-bootstrap";

/* Description of component
  A simple ManualCheck
*/
class ManualCheckForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      amount: 0,
      error: false,
    };

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }

  handleChangeAmount(event) {
    this.setState({ amount: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    // CHANGE THIS ADDRESS TO FINAL ADDRESS IN PRODUCTION
    // "http://localhost:3001" + process.env.PORT for testing
    // /api/ for production
   var backendBaseUrl = "https://freedomdinner.org:30005";

    //If any value is empty then reject the user
    if(this.isStringEmpty(this.state.email) || this.isStringEmpty(this.state.name) || this.isStringEmpty(this.state.amount)){
      this.setState({
        error: true,
      });
      return;
    }

    var payload = {
      name: this.state.name.replace(",", ""),
      email: this.state.email.replace(",", ""),
      amount: this.state.amount.replace(",", ""),
      isCheck: this.props.isCheck,
    };
    var xhttp = new XMLHttpRequest();
    xhttp.open("POST", backendBaseUrl + "/manualPledge");
    xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhttp.onload = function () {
      if (xhttp.status == 200) {
        // Send succeeded
        this.props.closePopup2();
        this.props.closePopup3();
        this.props.openPopupThanks();
      } else {
        // registeration failed
        // ERROR
        alert(xhttp.status);
        this.setState({
          error: true,
        });
      }
    }.bind(this);
    xhttp.send(JSON.stringify(payload));
  }

  isStringEmpty(string) {
    return (string.length === 0 || !string.trim());
  }


  render() {
    return (
        <div class="manual-pledge">
            <form onSubmit={this.handleSubmit}>
              <label>
                <input
                  type="text"
                  value={this.state.name}
                  onChange={this.handleChangeName}
                  id="name"
                  name="name"
                  placeholder="Full Name"
                />
              </label>
              <br />
              <label>
                <input
                  type="text"
                  value={this.state.email}
                  onChange={this.handleChangeEmail}
                  id="email"
                  name="email"
                  placeholder="Email Address"
                />
              </label>
              <br />
              <label>
                
                <input
                  type="text"
                  onChange={this.handleChangeAmount}
                  id="manual-pledge-amount"
                  name="amount"
                  placeholder="$0.00"
                />
              </label>
              <br />
              <input type="submit" value="Submit Pledge" />
            </form>
            <CSSTransition
              in={this.state.error}
              timeout={300}
              classNames="fadeable"
              unmountOnExit
            >
              <div className="register-error">
                <Alert
                  variant="primary"
                  dismissible
                  onClose={() => this.setState({error:false})}
                >
                  <Alert.Heading>Error</Alert.Heading>
                  <p>Looks like there was an error with what you entered</p>
                </Alert>
              </div>
            </CSSTransition>
        </div>
    );
  }
}

export default ManualCheckForm;
